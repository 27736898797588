import React from "react";
import "./styles.css";
import StarRating from "./StarRating";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/AuthContext";

function Reviews(props) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleWriteReviewClick = () => {
    if (isAuthenticated) navigate("/write-review");
    else
      navigate("/login", { state: { intendedDestination: "/write-review" } });
  };

  return (
    <div>
      <div className="header-button-container">
        <div className="header-large">
          Reviews{" "}
          {props.reviews.length > 0 ? "(" + props.reviews.length + ")" : ""}
        </div>
        <button
          onClick={handleWriteReviewClick}
          className="write-review-button"
        >
          Write a review
        </button>
      </div>
      <div className="main-text" style={{ whiteSpace: "pre-line" }}>
        {props.reviews.map((review, index) => (
          <div key={index}>
            <hr />
            <div style={{ display: "flex", alignItems: "center" }}>
              <StarRating rating={review.rating} />
              <div style={{ marginLeft: "10px" }}>
                Visited {review.dateOfStay}
              </div>
            </div>

            <div className="header-sub">{review.reviewTitle}</div>
            <div className="main-text">{review.reviewBody}</div>

            {/* Show the reply if it exists */}
            {review.reply && (
              <div className="reply-container">
                <strong>Owner's reply:</strong>
                <div className="reply-text">{review.reply}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reviews;
