import React, { useState } from "react";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { unconfirmReview, editReview } from "../Reviews/SubmitHandler.js";
import "../admin.css";

function Confirmed(props) {
  //
  // Unconfirm Icon
  //
  const [selectedReviewToUnconfirm, setSelectedReviewToUnconfirm] = useState(
    []
  );
  const [selectedReviewToUnconfirmID, setSelectedReviewToUnconfirmID] =
    useState([]);
  const [
    showUnconfirmReviewConfirmationModal,
    setShowUnconfirmReviewConfirmationModal,
  ] = useState(false);

  // When the user clicks the Unconfirm icon (trash can)
  const handleUnconfirmReviewClick = (email, id) => {
    setSelectedReviewToUnconfirm(email);
    setSelectedReviewToUnconfirmID(id);
    setShowUnconfirmReviewConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleUnconfirmReviewCancel = () => {
    setShowUnconfirmReviewConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleUnconfirmReviewConfirm = () => {
    unconfirmReview(selectedReviewToUnconfirmID);
    setShowUnconfirmReviewConfirmationModal(false);
  };

  //
  // Reply Icon
  //
  const [selectedReviewToReplyTo, setSelectedReviewToReplyTo] = useState([]);
  const [selectedReviewToReplyToID, setSelectedReviewToReplyToID] = useState(
    []
  );
  const [selectedReviewReply, setSelectedReviewReply] = useState("");
  const [selectedReview, setSelectedReview] = useState(null);
  const [showReplyModal, setShowReplyModal] = useState(false);

  // When the user clicks the Reply icon
  const handleReplyClick = (email, id, reply, review) => {
    setSelectedReviewToReplyTo(email);
    setSelectedReviewToReplyToID(id);
    setSelectedReviewReply(reply);
    setSelectedReview(review);
    setShowReplyModal(true);
  };

  // When the user clicks cancel in the modal
  const handleReplyCancel = () => {
    setShowReplyModal(false);
  };

  // When the user clicks confirm in the modal
  const handleReplyConfirm = () => {
    const { _id, ...reviewWithoutId } = selectedReview;

    const json = {
      reviewID: selectedReviewToReplyToID,
      updatedData: {
        ...reviewWithoutId,
        reply: selectedReviewReply,
      },
    };

    editReview(json);
    setShowReplyModal(false);
  };

  const rows = props.confirmedReviews.map((data, index) => {
    return (
      <tr key={index}>
        <td>{data.email}</td>
        <td>{data.rating}</td>
        <td>{data.dateOfStay}</td>
        <td>{data.reviewTitle}</td>
        <td>{data.reviewBody}</td>
        <td>
          <ReplyIcon
            style={{ cursor: "pointer", title: "Unconfirm Review" }}
            onClick={() =>
              handleReplyClick(data.email, data._id, data.reply, data)
            }
          />
        </td>
        <td>
          <ReplayIcon
            style={{ cursor: "pointer", title: "Unconfirm Review" }}
            onClick={() => handleUnconfirmReviewClick(data.email, data._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="header-large">Confirmed Reviews:</div>
      <table className="review-table">
        <thead>
          <tr>
            <th>Email:</th>
            <th>Rating:</th>
            <th>Date of Stay:</th>
            <th>Review Title:</th>
            <th>Review Body:</th>
            <th></th>
            <td></td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <Dialog
        open={showUnconfirmReviewConfirmationModal}
        onClose={handleUnconfirmReviewCancel}
      >
        <DialogTitle>Unconfirm Review</DialogTitle>
        <DialogContent>
          Are you sure you want to unconfirm the review left by "
          {selectedReviewToUnconfirm}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnconfirmReviewCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnconfirmReviewConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showReplyModal} onClose={handleReplyCancel}>
        <DialogTitle>
          Reply to review from {selectedReviewToReplyTo}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            fullWidth
            rows={5}
            variant="outlined"
            placeholder={selectedReviewReply}
            value={selectedReviewReply}
            onChange={(e) => setSelectedReviewReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReplyCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReplyConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Confirmed;
