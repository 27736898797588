import React from "react";
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LogoutIcon from "@mui/icons-material/Logout";
import MessageIcon from "@mui/icons-material/Message";
import { styled } from "@mui/material/styles";
import { useAuth } from "../Hooks/AuthContext";

// Define custom styles using styled function
const NavigationButton = styled(Button)(({ theme }) => ({
  "&.active": {
    background: "#538ec9",
  },
}));

const Navigation = (props) => {
  const { logout } = useAuth();
  const logoutClick = () => {
    logout();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {props.title}
        </Typography>
        <Box>
          <NavigationButton
            color="inherit"
            component={NavLink}
            to="/"
            startIcon={<HomeIcon />}
          >
            House
          </NavigationButton>
          <NavigationButton
            color="inherit"
            component={NavLink}
            to="/profile"
            startIcon={<PersonIcon />}
          >
            Profile
          </NavigationButton>
          <NavigationButton
            color="inherit"
            component={NavLink}
            to="/write-review"
            startIcon={<BorderColorIcon />}
          >
            Review
          </NavigationButton>
          <NavigationButton
            color="inherit"
            component={NavLink}
            to="/inquiries"
            startIcon={<RemoveRedEyeIcon />}
          >
            Inquiries
          </NavigationButton>
          <NavigationButton
            color="inherit"
            component={NavLink}
            to="/messages"
            startIcon={<MessageIcon />}
          >
            Messages
          </NavigationButton>
          <NavigationButton
            color="inherit"
            component={NavLink}
            onClick={logoutClick}
            to="/login"
            startIcon={<LogoutIcon />}
          >
            Logout
          </NavigationButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
