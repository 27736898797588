import React, { useState } from "react";
import "./messages.css";
import { submitMessage } from "./SubmitHandler.js";

function Messages(props) {
  const [newMessage, setNewMessage] = useState("");

  // Function to handle new message submission (to be implemented)
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messageJson = {
        dateCreated: new Date(),
        email: props.user.email,
        sentBy: "User",
        message: newMessage,
      };

      submitMessage(messageJson);
      window.location.reload();
    }
  };

  const messageItemStyles = {
    Owner: {
      backgroundColor: "#f0f0f0",
      textAlign: "left",
      padding: "10px",
      margin: "5px 0",
    },
    User: {
      backgroundColor: "#d1f7d6",
      textAlign: "right",
      padding: "10px",
      margin: "5px 0",
    },
  };

  return (
    <div className="messages-container">
      <div className="messages-list">
        {props.messages.map((message, index) => (
          <div
            key={index}
            style={messageItemStyles[message.sentBy]}
            className={`message-item`}
          >
            <strong>
              {message.sentBy === "User" ? "You" : props.ownerName}
              {"  "}
            </strong>
            <span>{message.message}</span>
          </div>
        ))}
      </div>

      {/* Message Input */}
      <div className="message-input">
        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default Messages;
