import React, { useState, useEffect } from "react";
import MessagesForUser from "./MessagesForUser";
import "./messages.css";

function Messages(props) {
  const [selectedEmail, setSelectedEmail] = useState(null);

  // Extract unique emails from props.messages
  const uniqueEmails = [
    ...new Set(props.messages.map((message) => message.email)),
  ];

  // Filter messages based on selected email
  const filteredMessages = selectedEmail
    ? props.messages.filter((message) => message.email === selectedEmail)
    : [];

  // Handle email click to toggle the message thread
  const handleEmailClick = (email) => {
    if (selectedEmail === email) {
      setSelectedEmail(null); // Deselect if the same email is clicked again
    } else {
      setSelectedEmail(email); // Select the email
      // Store the selected email in localStorage to maintain state after reload
      localStorage.setItem("selectedEmail", email);
    }
  };

  // Load the selected email from localStorage when the component mounts
  useEffect(() => {
    const savedEmail = localStorage.getItem("selectedEmail");
    if (savedEmail) {
      setSelectedEmail(savedEmail);
    }
  }, []);

  return (
    <div className="messages-container">
      <div className="email-list">
        <h2>Message Threads</h2>
        <ul>
          {uniqueEmails.map((email, index) => (
            <li key={index}>
              <button
                className={`email-link ${
                  selectedEmail === email ? "active" : ""
                }`}
                onClick={() => handleEmailClick(email)}
              >
                {email}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="message-thread">
        {selectedEmail && (
          <>
            <h3>Messages with {selectedEmail}</h3>
            <MessagesForUser
              email={selectedEmail}
              messages={filteredMessages}
            />
          </>
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Messages;
