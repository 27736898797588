import Axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

export const confirmReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.post(
    apiUrl + "/pending-reviews/confirm",
    {
      id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const unconfirmReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.post(
    apiUrl + "/pending-reviews/unconfirm",
    {
      id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const deleteReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/pending-reviews/delete?id=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const editReview = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.put(apiUrl + `/reviews/edit/`, json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
    return "Success";
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error saving review: " + error.message;
    }
  }
};
