import React, { useState, useEffect } from "react";
import "./styles.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

function Slideshow(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = props.slideshowImages;

  // Preload images
  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.path;
    });
  }, [images]);

  const handlePrevClick = (event) => {
    event.stopPropagation();
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const width = event.target.clientWidth;

    if (x < width / 2) {
      handlePrevClick(event);
    } else {
      handleNextClick(event);
    }
  };

  return (
    <div className="slideshow" onClick={handleImageClick}>
      <img
        src={images[currentIndex].path}
        alt={`${currentIndex}`}
        style={{ position: "relative" }}
      />

      <i className="arrow left" onClick={handlePrevClick}>
        &lt;
      </i>
      <i className="arrow right" onClick={handleNextClick}>
        &gt;
      </i>
    </div>
  );
}

export default Slideshow;
